footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .social {
    padding: 0;
    width: 320px;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-height: 400px) {
  footer {
    height: 75px;
  }
}
