.MeshText {
  cursor: pointer;
  width: 330px;
  height: 388px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MeshImg {
  height: 200px;
}

@media screen and (max-height: 400px) {
  .MeshImg {
    height: 75px;
  }
}
