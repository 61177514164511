header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  h1 {
    color: #3b3a3c;
    font-weight: 600;
    font-size: 28px;
    width: 180px;
  }

  .btn {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    width: 160px;
  }

  label {
    width: 160px;
    font-size: 1rem;
    color: #3b3a3c;
    transition: color 1000ms linear;
  }

  .label-dark {
    color: #f1f4f8;
    transition: color 1000ms linear;
  }
}

@media screen and (max-height: 400px) {
  header {
    height: 75px;
  }
}
