@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
  height: 80vh;
  margin: 40px auto;
  overflow: hidden;
}

body {
  background-color: #f1f4f8;
  transition: background-color 1000ms linear;
  position: fixed;
}

.body-dark {
  background-color: #23262b;
  transition: background-color 1000ms linear;
}

a {
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
}

.light {
  color: #3b3a3c;
  transition: color 1000ms linear;
  &:hover {
    color: #000;
  }
  &:active {
    color: #05c7f2;
  }
  &:focus {
    color: #05c7f2;
  }
  &:visited {
    color: #3b3a3c;
  }
}

.dark {
  color: #f1f4f8;
  transition: color 1000ms linear;
  &:hover {
    color: #000;
  }
  &:active {
    color: #05c7f2;
  }
  &:focus {
    color: #05c7f2;
  }
  &:visited {
    color: #f1f4f8;
  }
}

.AboutMe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  margin: auto;
  img {
    border-radius: 120px;
  }
  ul {
    padding: 0;
    display: flex;
    width: 320px;
    justify-content: space-evenly;
  }
  p {
    line-height: 1.5rem;
    text-align: center;
    width: 90%;
  }
}

.touring {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  margin: auto;
  ul {
    padding: 0;
    display: flex;
    width: 320px;
    justify-content: space-evenly;
  }
  p {
    line-height: 1.5rem;
    width: 90%;
  }
  h5 {
    margin-top: 10px;
  }
}

.projects {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  margin: auto;
  ul {
    padding: 0;
    display: flex;
    justify-content: center;
    width: 320px;
  }

  p {
    line-height: 1.5rem;
    width: 90%;
  }
  h5 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .AboutMe,
  .touring,
  .projects {
    p {
      margin: 0;
    }
  }
}
